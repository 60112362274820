<template>
  <div>
    <div class="main-header">
      <div class="title">统计管理</div>
    </div>
    <div class="main-main">
      <a-card>
        <div class="search-card">
          <div class="search-row">
            <div class="search-option">
              <span class="label">开始日期</span>
              <a-date-picker
                style="flex: 1"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                v-model="searchOptions.startTime"
              ></a-date-picker>
            </div>
            <div class="search-option">
              <span class="label">结束日期</span>
              <a-date-picker
                style="flex: 1"
                format="YYYY-MM-DD"
                valueFormat="YYYY-MM-DD"
                v-model="searchOptions.endTime"
              ></a-date-picker>
            </div>
            <div class="search-option">
              <span class="label">所属组织</span>
              <a-tree-select
                allowClear
                class="search-select"
                v-model="searchOptions.groupId"
                :tree-data="groupTree"
                placeholder="请选择所属组织"
              >
              </a-tree-select>
            </div>
            <div class="empty">
              <a-button type="primary" @click="search" icon="search"
                >搜索</a-button
              >
            </div>
          </div>
        </div>
        <div class="optionsAndPagination"></div>
        <div class="actions">
          <div></div>
        </div>
        <div>
          <a-table
            size="middle"
            :loading="tableLoading"
            :data-source="tableData"
            :columns="tableColumns"
            :pagination="false"
            :scroll="{ x: 10 }"
          >
          </a-table>
          <div style="height: 500px">
            <v-chart :option="option"></v-chart>
          </div>
        </div>
      </a-card>
    </div>
  </div>
</template>

<script>
// import * as echarts from "echarts";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart } from "echarts/charts";
import {
  GridComponent,
  TooltipComponent,
  LegendComponent,
  TitleComponent,
} from "echarts/components";

use([
  CanvasRenderer,
  GridComponent,
  TooltipComponent,
  LegendComponent,
  LineChart,
  TitleComponent,
]);

import ECharts from "vue-echarts";
import { mapState } from "vuex";
import { getStats, zxt } from "@/network/api";
import Pagination from "../components/Pagination";
export default {
  components: {
    Pagination,
    "v-chart": ECharts,
  },
  data() {
    return {
     
      option: {
        title: {
          text: "统计数据折线图",
        },
        tooltip: {
          trigger: "axis",
        },
        legend: {
          data: ["活动数", "参与人次", "每日新增人数"],
        },
        grid: {
        },
        xAxis: {
          type: "category",
          data: [],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            name: "活动数",
            type: "line",
            data: [],
          },
          {
            name: "参与人次",
            type: "line",
            data: [],
          },
          {
            name: "每日新增人数",
            type: "line",
            data: [],
          },
        ],
      },
      tableLoading: false,
      total: 0,
      tableData: [],
      searchOptions: {},
    };
  },
  methods: {
    formatHour(val) {
      if (val == 0) {
        return 0;
      }
      return ((val / 3600).toFixed(1) * 100) / 100;
    },
    search() {
      this.getStats(this.searchOptions);
      this.getLinechartData(this.searchOptions);
    },
    async getStats(params = {}) {
      this.tableLoading = true;
      const { data } = await getStats(params);
      this.tableData = data.map((item) => {
        item.timeSeconds = this.formatHour(item.timeSeconds);

        return item;
      });
      this.tableLoading = false;
      this.total = this.tableData.length;
    },
    getLinechartData(pa = {}) {
      zxt(pa).then((res) => {
      this.option.xAxis.data = []
      this.option.series[0].data =[]
      this.option.series[1].data =[]
      this.option.series[2].data = []
      res.data.forEach((item) => {
          this.option.xAxis.data.push(item.date);
          this.option.series[0].data.push(item.activityNum);
          this.option.series[1].data.push(item.peopleNum);
          this.option.series[2].data.push(item.addUserNum);
        });
      });
      console.log(this.option);
    },
  },

  created() {
    this.getStats();
    this.getLinechartData();
  },
  computed: {
    ...mapState(["groupTree"]),
    tableColumns() {
      return [
        {
          dataIndex: "groupName",
          title: "组织名称",
          align: "center",
        },
        {
          dataIndex: "peopleCount",
          title: "用户总数",
          align: "center",
        },
        {
          dataIndex: "timeMoneyCount",
          title: "时间币余额",
          align: "center",
        },
        {
          dataIndex: "activityCount",
          title: "有效活动数",
          align: "center",
        },
        {
          dataIndex: "joinActivityCount",
          title: "参与活动总人次",
          align: "center",
        },
        {
          dataIndex: "timeSeconds",
          title: "累计时长",
          align: "center",
        },
        {
          dataIndex: "newUserNum",
          title: "每日新增人数",
          align: "center"
        },
      ];
    },
  },
};
</script>

<style lang="less" scoped>
.title {
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #333333;
  font-size: 22px;
}
.date-picker {
  display: flex;
  align-items: center;
}

.ant-card-body {
  padding: 0.56rem 1.06rem;
}
.ant-tabs-bar {
  margin: 0;
}

.ant-tabs-tab {
  border-radius: none !important;
}

.ant-table-row-cell-break-word {
  text-align: center !important;
}

.pagination {
  display: flex;
}
.actions {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
.actions .label {
  display: flex;
  align-items: center;
}
.actions .btns {
  display: flex;
  justify-content: space-between;
}
.excel-btn {
  padding: 0 !important;
  border: 0 !important;
  background-color: transparent !important;
  cursor: pointer;
  vertical-align: middle;
}
.excel-btn:after {
  top: 0;
  left: 0;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border-radius: 4px;
}
.ant-tabs-tab {
  margin-right: 0;
}

.search-card {
  margin-bottom: 10px;
}
.admin-list {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 15px;
}
.admin-info {
  flex: 1;
  text-align: center;
}
</style>